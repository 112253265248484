import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H3 } from "../typography/Headings"
import P from "../typography/P"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const CardAbout = ({ image, title, text, link }) => {
  return (
    <div>
      <div className="grid items-center gap-5 md:grid-cols-3">
        <div className="relative text-center">
          <Link to={link}>
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className="align-top"
            />
          </Link>
        </div>
        <div className="col-span-2">
          <Link
            to={link}
            className="transition-colors duration-300 hover:text-brand"
          >
            <H3 className="mb-3 font-display">{title}</H3>
          </Link>
          <P className="mb-3">{text}</P>
          {link && (
            <Link
              to={link}
              className="transition-colors duration-300 hover:text-brand"
            >
              Mehr Infos
              <ChevronDoubleRightIcon className="inline w-4 h-4 ml-2" />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardAbout
