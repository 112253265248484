import React from "react"
import { graphql } from "gatsby"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"
import PageHeader from "../components/common/PageHeader"
import Breadcrumb from "../components/common/Breadcrumb"
import CardAbout from "../components/common/CardAbout"
import P from "../components/typography/P"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Verlag" desc="">
      <PageHeader title="Über uns" image={data.header} />
      <Breadcrumb pages={[{ name: "Über uns", to: "/ueber-uns/" }]} />

      <Container narrow>
        <div className="space-y-6">
          <CardAbout
            image={data.peter}
            title="Peter Scherbuk"
            text="Bereits seit seinem zwölften Lebensjahr beschäftigt er sich mit der Fotografie. Seine Liebe zur Natur hat tiefe Wurzeln, denn er stammt aus einer sehr naturverbundenen ostpreußischen Familie."
          />
          <P>
            Der gelernte Grafik-Designer beschäftigt sich intensiv mit der
            europäischen Tierwelt. Eine besondere Faszination üben auf ihn die
            Greifvögel, die Kraniche und die Elche aus. Aber auch die
            Landschaftsfotografie kommt bei ihm nicht zu kurz. In Masuren und in
            Skandinavien, vorwiegend in Norwegen und in Finnland, verbringt er
            mittlerweile mehrere Monate im Jahr.
          </P>
          <P>
            Der leidenschaftliche Naturfotograf und Buchautor ist auch
            Chefredakteur der Zeitschrift NATURBLICK, die er vor 15 Jahren auf
            den Markt gebracht hat. Er gibt dieses Magazin in seinem eigenen
            Verlag heraus, genauso wie Bücher und Kalender. Seit mehr als zehn
            Jahren organisiert und leitet er verschiedene
            Natur-Fotografie-Workshops, Fotoexkursionen und Fotoreisen, die als
            eigenständige NATURBLICK-Veranstaltungen oder in Kooperation mit
            Partnerfirmen stattfinden.
          </P>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/index/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    peter: file(relativePath: { eq: "pages/index/peter.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
